import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@/assets/main.css'

import { register } from 'register-service-worker'

register('firebase-messaging-sw.js')

Vue.config.productionTip = false


import firebaseMessaging from './firebase'

Vue.prototype.$messaging = firebaseMessaging
//App.config.globalProperties.$messaging = firebaseMessaging

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
