//firebase.js

import firebase from 'firebase/app'
import 'firebase/firebase-messaging'

var firebaseConfig = {
    apiKey: "AIzaSyC5UJSGUVQxKLtv88OhfT2f9ZEDQX2D2i8",
    authDomain: "remi-pradere-podologue.firebaseapp.com",
    projectId: "remi-pradere-podologue",
    storageBucket: "remi-pradere-podologue.appspot.com",
    messagingSenderId: "373944791571",
    appId: "1:373944791571:web:a0b5eb3fa351367ffc85e6",
    measurementId: "G-Y3Z2EY906F"
}

firebase.initializeApp(firebaseConfig)

export default firebase.messaging()