<template>
<div>
  <!-- <Header name="Contact"/> -->
  <Main/>
  <Form/>
</div>
</template>

<script>
import Header from '../components/contact/Header.vue';
import Main from '../components/contact/Main.vue';
import Form from '../components/contact/Form.vue';
export default {
    components:{
        Header,Main,Form
    }
}
</script>

<style>

</style>