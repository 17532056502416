<template>
  <v-app>
    <div class="installBannerMobile">
      <v-row v-if="install" class="rowMenu add-button" justify="center">
      <h2> Faciliter vous la vie en telechargeant l'application</h2> 
      <p>Voulez vous l'installer sur votre smartphone?</p>
        <div>
          <v-btn class=""> Installer Appli </v-btn>
          <v-btn @click="install = false"> Fermer</v-btn>
        </div>
      </v-row>
      <BandDrawer />
      <Drawer />
      <v-main>
        <router-view></router-view>
      </v-main>
      <v-btn target="_blank" href="https://www.doctolib.fr/pedicure-podologue/condom/remi-pradere?utm_campaign=website-button&amp;utm_source=remi-pradere-website-button&amp;utm_medium=referral&amp;utm_content=option-5&amp;utm_term=remi-pradere" class="btnPhone">
        <v-icon>mdi-phone</v-icon>
      </v-btn>
    </div>
  </v-app>
</template>

<script>
import HelloWorld from "./components/HelloWorld";
import Drawer from "./components/menu/Drawer.vue";
import BandDrawer from "./components/menu/BandDrawer.vue";

export default {
  name: "App",

  components: {
    HelloWorld,
    Drawer,
    BandDrawer,
  },

  data: () => ({
    install: true,
  }),
  mounted() {
    let deferredPrompt;
    const addBtn = document.querySelector(".add-button");
    addBtn.style.display = "none";
    console.log(addBtn);

    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      // Update UI to notify the user they can add to home screen
      addBtn.style.display = "block";
      console.log(deferredPrompt);
      addBtn.addEventListener("click", (e) => {
        // hide our user interface that shows our A2HS button
        addBtn.style.display = "none";
        // Show the prompt
        deferredPrompt.prompt();
        // Wait for the user to respond to the prompt
        deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === "accepted") {
            console.log("User accepted the A2HS prompt");
          } else {
            console.log("User dismissed the A2HS prompt");
          }
          deferredPrompt = null;
        });
      });
    });
  },
};
</script>
<style scoped>
main {
  margin-top:-10px;
}
.btnPhone {
  opacity: 0;
}
.rowMenu {
  display: none;
}
@media screen and (max-width: 600px) {
  .rowMenu {
    z-index: 1111112;
    position: absolute;
    inset: 0px;
    background-color:navy;
    width: 100% !important;
    height: 100% !important;
    display: block;
  }

  .installBannerMobile h2 , .installBannerMobile p {
    color:white;
    padding:10px;
  } 

  .btnPhone {
    opacity: 1;
    position: fixed;
    top: 450px;
    left: 73%;
    z-index: 10000;
    background-color: orange !important;
    color: white;
    height: 50px !important;
    border-radius: 50%;
  }

  .rowMenu div {
        position: absolute;
    top: 20%;
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .rowMenu div .v-btn {
    margin-bottom:20px!important
  }
}
</style>