import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    drawer:false
  },
  mutations: {
    GET_DATA_DRAWER_OPEN(state){
      state.drawer = true
    }
  },
  actions: {
    openmenu({commit}){
      commit("GET_DATA_DRAWER_OPEN")
    }
  },
  modules: {
  }
})
