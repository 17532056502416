<template>
<div>
  <Header name="Votre message a bien été envoyé"/>
  <v-card
  elevation="2"
  tile
></v-card>
</div>
</template>

<script>
import Header from '../components/contact/Header.vue';

export default {
components:{
  Header
}

}
</script>

<style>

</style>